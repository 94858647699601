if(! $('.noScroll').length)
{
	if ($(window).width() < 1024) {
		$(window).scroll(function () {
			if ($(window).scrollTop() > 61) {
				$('#headerPhone').addClass('fixed');
				$('body').css({ 'margin-top': '61px' });
			}
			if ($(window).scrollTop() < 61) {
				$('#headerPhone').removeClass('fixed');
				$('body').css({ 'margin-top': '0' });
			}
		});
	}
	else {
		$(window).scroll(function () {
			if ($(window).scrollTop() > 80) {
				$('.navbar').addClass('fixed');
				$('body').css({ 'margin-top': '53px' });
			}
			if ($(window).scrollTop() < 81) {
				$('.navbar').removeClass('fixed');
				$('body').css({ 'margin-top': '0' });
			}
		});

	}

}