var devoirParProf = {

    init: function(){
    	this.interface = Object.create(Interface);
    	this.interface.init();
        this.interface.typeAffecatation = "profParDevoir";
        this.interface.initAffectPar();
        this.interface.initImportFrom();
        this.initListener();
    },

    initListener: function(){
        this.interface.initListener();
    }
};
