function generateInputVideo(type, index) {
    var regexYT = /^(https?:\/\/)?(www\.)?youtube\.com\/watch\?/;
    var regexDaily = /^(https?:\/\/)?(www\.)?dailymotion\.com\/video\//;
    var regexVimeo = /^(https?:\/\/)?(www\.)?vimeo\.com\//;
    var elVideoJointeQuestion = document.querySelector('#template-ajout-video-jointe-taq').cloneNode(true);
    elVideoJointeQuestion.style.display = 'block';
    elVideoJointeQuestion.id += ('-' + type + '-' + index);
    elVideoJointeQuestion.dataset.type = type;
    elVideoJointeQuestion.dataset.questionindex = index;
    elVideoJointeQuestion.dataset.videoindex = 0;
    elVideoJointeQuestion.querySelector('#template-input-video-jointe').id += ('-' + type + '-' + index + '-0');
    elVideoJointeQuestion.querySelector('label').innerHTML = (type == "enonce" ? "Video d'illustration (énoncé / question) :<br><i>(Hébergeurs supportés : Youtube, Dailymotion, Vimeo)</i>" : "Video correction : <br><i>(Hébergeurs supportés : Youtube, Dailymotion, Vimeo)</i>");
    elVideoJointeQuestion.querySelector('input').name = (type == 'enonce' ? 'question-' + index + '-video-0' : 'question-' + index + '-response-video-0');

    elVideoJointeQuestion.querySelector('button[name="trigger-supprimer"]').addEventListener('click', function() {
        var typeIn = this.parentNode.dataset.type;
        deleteVideo(this.parentNode, type);
    });

    $(elVideoJointeQuestion.querySelector('input')).on('change', function () {
        var lienYT = regexYT.test(elVideoJointeQuestion.querySelector('input').value);
        var lienDaily = regexDaily.test(elVideoJointeQuestion.querySelector('input').value);
        var lienVimeo = regexVimeo.test(elVideoJointeQuestion.querySelector('input').value);
        if (lienYT || lienDaily || lienVimeo) {
            $('p.erreur').remove();
            $(elVideoJointeQuestion.querySelector('input')).css('box-shadow', '0 0 5px green');
            $(elVideoJointeQuestion.querySelector('input')).parent().after('<p class="erreur"><br><font color = green>Url validé.</font></p>');
            createInputVideoJointe(event, elVideoJointeQuestion, type);
        } else {
            $('p.erreur').remove();
            $(elVideoJointeQuestion.querySelector('input')).css('box-shadow', '0 0 5px red');
            $(elVideoJointeQuestion.querySelector('input')).parent().after('<p class="erreur"><br>Votre Url est invalide!<br>Rappel forme url : <i>http(s)://(www.)votre_hebergeur/identifiant_video</i></p>');
        }
    });

    $(elVideoJointeQuestion.querySelector('input')).keypress(function() {
        var lienYT = regexYT.test(elVideoJointeQuestion.querySelector('input').value);
        var lienDaily = regexDaily.test(elVideoJointeQuestion.querySelector('input').value);
        var lienVimeo = regexVimeo.test(elVideoJointeQuestion.querySelector('input').value);
        if (lienYT || lienDaily || lienVimeo) {
            $('p.erreur').remove();
            $(elVideoJointeQuestion.querySelector('input')).css('box-shadow', '0 0 5px green');
            $(elVideoJointeQuestion.querySelector('input')).parent().after('<p class="erreur"><br><font color = green>Url validé.</font></p>');
            createInputVideoJointe(event, elVideoJointeQuestion, type);
        } else {
            $('p.erreur').remove();
            $(elVideoJointeQuestion.querySelector('input')).css('box-shadow', '0 0 5px red');
            $(elVideoJointeQuestion.querySelector('input')).parent().after('<p class="erreur"><br>Votre Url est invalide!<br>Rappel forme url : <i>http(s)://(www.)votre_hebergeur/identifiant_video</i></p>');
        }
    });
    return elVideoJointeQuestion;
}

function generateInputPhoto(type, index) {
    var elImgJointeQuestion = document.querySelector('#template-ajout-img-jointe-taq').cloneNode(true);
    elImgJointeQuestion.style.display = 'block';
    elImgJointeQuestion.id += ('-' + type + '-' + index);
    elImgJointeQuestion.dataset.type = type;
    elImgJointeQuestion.dataset.questionindex = index;
    elImgJointeQuestion.dataset.photoindex = 0;
    elImgJointeQuestion.querySelector('#template-input-img-jointe').id += ('-' + type + '-' + index + '-0');
    elImgJointeQuestion.querySelector('label').innerHTML = (type == "enonce" ? "Image(s) d'illustration (énoncé / question)" : "Image(s) correction");
    elImgJointeQuestion.querySelector('input').name = (type == 'enonce' ? 'question-' + index + '-photo-0' : 'question-' + index + '-response-photo-0');
    elImgJointeQuestion.querySelector('.ajouter-piece-jointe').id = 'ajouter-piece-jointe-' + type + '-' + index;
    elImgJointeQuestion.querySelector('#ajouter-piece-jointe-' + type + '-' + index).addEventListener('click', function(event) {
        var typeIn = this.parentNode.dataset.type;
        createInputImgJointe(event, this.parentNode, type);
    });
    elImgJointeQuestion.querySelector('button[name="trigger-supprimer"]').addEventListener('click', function() {
        var typeIn = this.parentNode.dataset.type;
        deleteImg(this.parentNode, type);
    });
    return elImgJointeQuestion;
}

function createInputVideoJointe(event, el, type) {
    if (event) {
        event.preventDefault();
    }
    var questionId = el.dataset.questionindex;
    var lastVideoId = el.dataset.videoindex;
    var valide = false;
    var regexYT = /^(https?:\/\/)?(www\.)?youtube\.com\/watch\?/;
    var regexDaily = /^(https?:\/\/)?(www\.)?dailymotion\.com\/video\//;
    var regexVimeo = /^(https?:\/\/)?(www\.)?vimeo\.com\//;
    $('p.erreur').remove();
    var selecteur = (type == "enonce" ? 'question-' + questionId + '-video-' + lastVideoId : 'question-' + questionId + '-response-video-' + lastVideoId);
    if (el.querySelector('input[name=' + selecteur + ']') && el.querySelector('input[name=' + selecteur + ']').value == '' && !valide) {
        $('input[name=' + selecteur + ']').css('box-shadow', '0 0 5px red');
        $('input[name=' + selecteur + ']').parent().before('<p class="erreur">Vous devez remplir la pièce jointe pour en ajouter une nouvelle.</p>');
        return false;
    } else {
        $('input[name=' + selecteur + ']').css('box-shadow', 'none');
        valide = false;
    }
    el.dataset.videoindex++;
    var selecteur = (type == "enonce" ? 'question-' + questionId + '-video-' + el.dataset.videoindex : 'question-' + questionId + '-response-video-' + el.dataset.videoindex);

    var elVideoJointeQuestion = document.querySelector('#template-ajout-video-jointe-taq #template-input-video-jointe').cloneNode(true);
    elVideoJointeQuestion.id += ('-' + type + '-' + questionId + '-' + el.dataset.videoindex);
    elVideoJointeQuestion.querySelector('input[name="video"]').name = selecteur;
    el.value = lastVideoId;
    elVideoJointeQuestion.querySelector('button[name="trigger-supprimer"]').addEventListener('click', function() {
        var typeIn = this.parentNode.dataset.type;
        deleteVideo(this.parentNode, type);
    });

    $(elVideoJointeQuestion.querySelector('input')).on('change', function () {
        var lienYT = regexYT.test(elVideoJointeQuestion.querySelector('input').value);
        var lienDaily = regexDaily.test(elVideoJointeQuestion.querySelector('input').value);
        var lienVimeo = regexVimeo.test(elVideoJointeQuestion.querySelector('input').value);
        if (lienYT || lienDaily || lienVimeo) {
            $('p.erreur').remove();
            $(elVideoJointeQuestion.querySelector('input')).css('box-shadow', '0 0 5px green');
            $(elVideoJointeQuestion.querySelector('input')).parent().after('<p class="erreur"><br><font color = green>Url validé.</font></p>');
            createInputVideoJointe(event, elVideoJointeQuestion.parentNode, type);
        } else {
            $('p.erreur').remove();
            $(elVideoJointeQuestion.querySelector('input')).css('box-shadow', '0 0 5px red');
            $(elVideoJointeQuestion.querySelector('input')).parent().after('<p class="erreur"><br>Votre Url est invalide!<br>Rappel forme url : <i>http(s)://(www.)votre_hebergeur/identifiant_video</i></p>');
        }
    });

    $(elVideoJointeQuestion.querySelector('input')).keypress(function() {
        var lienYT = regexYT.test(elVideoJointeQuestion.querySelector('input').value);
        var lienDaily = regexDaily.test(elVideoJointeQuestion.querySelector('input').value);
        var lienVimeo = regexVimeo.test(elVideoJointeQuestion.querySelector('input').value);
        if (lienYT || lienDaily || lienVimeo) {
            $('p.erreur').remove();
            $(elVideoJointeQuestion.querySelector('input')).css('box-shadow', '0 0 5px green');
            $(elVideoJointeQuestion.querySelector('input')).parent().after('<p class="erreur"><br><font color = green>Url validé.</font></p>');
            createInputVideoJointe(event, elVideoJointeQuestion.parentNode, type);
        } else {
            $('p.erreur').remove();
            $(elVideoJointeQuestion.querySelector('input')).css('box-shadow', '0 0 5px red');
            $(elVideoJointeQuestion.querySelector('input')).parent().after('<p class="erreur"><br>Votre Url est invalide!<br>Rappel forme url : <i>http(s)://(www.)votre_hebergeur/identifiant_video</i></p>');
        }
    });
    el.append(elVideoJointeQuestion);
}

function createInputImgJointe(event, el, type) {
    if (event) {
        event.preventDefault();
    }
    var questionId = el.dataset.questionindex;
    var lastPhotoId = el.dataset.photoindex;
    $('p.erreur').remove();
    var selecteur = (type == "enonce" ? 'question-' + questionId + '-photo-' + lastPhotoId : 'question-' + questionId + '-response-photo-' + lastPhotoId);
    if (el.querySelector('input[name=' + selecteur + ']') && el.querySelector('input[name=' + selecteur + ']').files.length < 1) {
        $('input[name=' + selecteur + ']').css('box-shadow', '0 0 5px red');
        $('input[name=' + selecteur + ']').parent().after('<p class="erreur">Vous devez remplir la pièce jointe pour en ajouter une nouvelle.</p>');
        return false;
    } else {
        $('input[name=' + selecteur + ']').css('box-shadow', 'none');
    }
    el.dataset.photoindex++;
    var selecteur = (type == "enonce" ? 'question-' + questionId + '-photo-' + el.dataset.photoindex : 'question-' + questionId + '-response-photo-' + el.dataset.photoindex);
    var elImgJointeQuestion = document.querySelector('#template-ajout-img-jointe-taq #template-input-img-jointe').cloneNode(true);
    elImgJointeQuestion.id += ('-' + type + '-' + questionId + '-' + el.dataset.photoindex);
    elImgJointeQuestion.querySelector('input[name="none"]').name = selecteur;
    elImgJointeQuestion.querySelector('button[name="trigger-supprimer"]').addEventListener('click', function() {
        var typeIn = this.parentNode.dataset.type;
        deleteImg(this.parentNode, type);
    });
    el.appendChild(elImgJointeQuestion);
}

function deleteImg(element, type) {
    var parent = element.parentNode;
    var questionId = parent.dataset.questionindex;
    var lastPhotoId = parent.dataset.photoindex;
    parent.dataset.photoindex--;
    parent.removeChild(element);
    if (!parent.querySelector('#template-input-img-jointe-' + type + '-' + questionId + '-0')) {
        createInputImgJointe(null, parent, type);
    }
}

function deleteVideo(element, type) {
    var parent = element.parentNode;
    var questionId = parent.dataset.questionindex;
    var lastVideoId = parent.dataset.videoindex;
    parent.dataset.videoindex--;
    parent.removeChild(element);
    if (!parent.querySelector('#template-input-video-jointe-' + type + '-' + questionId + '-0')) {
        createInputVideoJointe(null, parent, type);
    }
}