
$('.export-csv').click(function(e) {
    e.preventDefault();
    var url = $(this).attr('id');

    exportQuestionnaireEFC(url);
});

function exportQuestionnaireEFC(url){
    var oldUrl =  $('#formMoyenneEFC').prop('action');
    $('#formMoyenneEFC').prop('action', url).submit().prop('action', oldUrl);
}