$('body').on('change', '.ajout-image input.get-nom-fichier', function(){
	var nomFichier = $(this)[0].files[0].name;

	var infosAvatar = $(this).siblings('.infos-avatar');
	var formatInterdit = $(this).siblings('.format-interdit');

	var tableauNomFichier = nomFichier.split('.');
	var extension = tableauNomFichier[tableauNomFichier.length - 1];

	if(extension.toLowerCase() === 'jpg' ||extension.toLowerCase() === 'jpeg')
	{
		if(infosAvatar.hasClass('hidden'))
		{
			infosAvatar.removeClass('hidden');
		}
		if(!formatInterdit.hasClass('hidden'))
		{
			formatInterdit.addClass('hidden');
		}

		infosAvatar.find('.nom-fichier').html(nomFichier);
	}

	else
	{
		if(!infosAvatar.hasClass('hidden'))
		{
			infosAvatar.addClass('hidden');
		}

		if(formatInterdit.hasClass('hidden'))
		{
			formatInterdit.removeClass('hidden');
		}

		formatInterdit.find('.nom-fichier').html(nomFichier);
	}
});

$('.crop-image .cropper').cropper({
	aspectRatio: 1 / 1,
	crop: function(e) {
	    // Output the result data for cropping image.
	    $('input[name="x"]').val(e.x);
	    $('input[name="y"]').val(e.y);
	    $('input[name="width"]').val(e.width);
	    $('input[name="height"]').val(e.height);
  }
});